<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CDataTable
          :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
          :hover="true"
          :striped="true"
          :items-per-page="20"
          columnFilter
          pagination
          sorter
          :items="getHotelListData"
          :clickableRows="true"
          :loading="loading"
          :fields="fields"
          size="sm"
          :tableFilterValue.sync="filter"
          @row-clicked="openHotelDetail"
        >
          <template #user_id="{ item }">
            <td>{{ userEmail(item.user_id) }}</td>
          </template>
          <template #tariff_id="{ item }">
            <td>{{ nameTariff(item.tariff_id) }}</td>
          </template>
          <template #position="{ item }">
            <td>
              <Pencil :size="20" @click.stop="changeItem(item)"></Pencil>
              <label :for="item.id" style="cursor: pointer"
                ><Upload :size="20"></Upload
              ></label>
              <input
                type="file"
                :id="item.id"
                style="display: none"
                v-on:change="setJson"
              />
              <Download :size="20" @click.stop="getJson(item.id)"></Download>
              <Delete
                :size="20"
                @click.stop="showDeleteModal(item.id)"
              ></Delete>
                <AccountTieVoice @click.stop="showCopyWithChangeModal(item.id)" :size="20">

                </AccountTieVoice>
              <ImageAlbum
                :size="20"
                @click.stop="getDownloadZipImages(item.id)"
              />
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <HotelChangeModal
      :show="showEdit"
      :item="item"
      @close="closeModal"
      @accept="editItem"
    />
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
      <HotelChangeUser
          :show-modal="showCopyWithChange"
          @close="hideCopyWithChange"
          @accept="confirmCopyWithChange"
      />
  </CContainer>
</template>
<script>
import { CDataTable, CRow, CCol, CContainer } from "@coreui/vue/src";
import { mapActions, mapGetters } from "vuex";
import { TariffEnums } from "@/enums/TariffEnums";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import HotelChangeModal from "@/components/modals/HotelChangeModal";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import Download from "vue-material-design-icons/Download.vue";
import Upload from "vue-material-design-icons/Upload.vue";
import ImageAlbum from "vue-material-design-icons/ImageAlbum.vue";
import AccountTieVoice from "vue-material-design-icons/AccountTieVoice.vue"
import HotelChangeUser from "@/components/modals/HotelChangeUser";
export default {
  data() {
    return {
      showCopyWithChange:false,
      item: {},
      loading: false,
      filter: "",
      showDelete: false,
      deleteItemID: "",
      showEdit: false,
      selectedHotelID:null,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Name", key: "name", sorter: true, filter: true },
        { label: "Address", key: "address", sorter: true, filter: true },
        { label: "Created Date", key: "created_at", sorter: true, filter: true },
        { label: "Email", key: "user_id", sorter: true, filter: true },
        { label: "Tariff", key: "tariff_id", sorter: false, filter: false },
        { label: "Options", key: "position", sorter: false, filter: false },
      ],
    };
  },
  components: {
    HotelChangeUser,
    DeleteConfirmModal,
    HotelChangeModal,
    CDataTable,
    CRow,
    CCol,
    CContainer,
    Pencil,
    Delete,
    Download,
    Upload,
    ImageAlbum,
    AccountTieVoice
  },
  methods: {
    ...mapActions("hotelModule", [
      "getHotelList",
      "editHotel",
      "deleteHotel",
      "getUploadJson",
      "setUploadJson",
      "getDownloadZipImages",
      "changeUser"
    ]),
    ...mapActions("userModule", ["getUserList"]),
    showCopyWithChangeModal(id){
      this.selectedHotelID = id;
      this.showCopyWithChange = true;
    },
    hideCopyWithChange(){
      this.showCopyWithChange = false;
    },
    async confirmCopyWithChange(user){
      const payload = {
        hotel_id:this.selectedHotelID,
        user_id:user
      };
      await this.changeUser(payload);
      this.showCopyWithChange = false;
    },
    openHotelDetail(hotel) {
      this.$router.push("/hotels/" + hotel.id);
    },
    setJson(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.setUploadJson({ hotel_id: e.target.id, file: files[0] });
    },
    getJson(id) {
      this.getUploadJson(id);
    },
    nameTariff(id) {
      if (id === TariffEnums.Free) {
        return "Free";
      } else if (id === TariffEnums.Standart) {
        return "Standart";
      } else if (id === TariffEnums.Profi) {
        return "Profi";
      } else {
        console.log(id);
        return "Unknown Tariff";
      }
    },
    async editItem() {
      let req = {
        id: this.item.id,
        hotel: this.item,
      };
      await this.editHotel(req);
      this.showEdit = false;
    },
    async deleteItem() {
      await this.deleteHotel(this.deleteItemID);
      this.deleteItemID = "";
      this.showDelete = false;
    },
    showDeleteModal(id) {
      this.showDelete = true;
      this.deleteItemID = id;
    },
    userEmail(id) {
      let candidate = this.getUserListData.find((x) => x.id === id);
      if (candidate) {
        return candidate.email;
      } else {
        return "";
      }
    },
    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = "";
    },
    changeItem(item) {
      this.item = item;
      this.showEdit = true;
    },
    closeModal() {
      this.showEdit = false;
      this.item = {};
    },
  },
  async mounted() {
    await this.getHotelList();
    if (this.getUserListData.length === 0) {
      await this.getUserList();
    }
  },
  computed: {
    ...mapGetters("hotelModule", ["getHotelListData"]),
    ...mapGetters("userModule", ["getUserListData"]),
  },
};
</script>
<style lang="scss">
.mainTable {
  &Container {
    padding-top: 15px;
  }
}
</style>
