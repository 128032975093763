var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"mainTableContainer",attrs:{"fluid":""}},[_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"noItemsView":{
          noResults: 'no items found',
          noItems: 'no items',
        },"hover":true,"striped":true,"items-per-page":20,"columnFilter":"","pagination":"","sorter":"","items":_vm.getHotelListData,"clickableRows":true,"loading":_vm.loading,"fields":_vm.fields,"size":"sm","tableFilterValue":_vm.filter},on:{"update:tableFilterValue":function($event){_vm.filter=$event},"update:table-filter-value":function($event){_vm.filter=$event},"row-clicked":_vm.openHotelDetail},scopedSlots:_vm._u([{key:"user_id",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.userEmail(item.user_id)))])]}},{key:"tariff_id",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.nameTariff(item.tariff_id)))])]}},{key:"position",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('Pencil',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.changeItem(item)}}}),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"for":item.id}},[_c('Upload',{attrs:{"size":20}})],1),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":item.id},on:{"change":_vm.setJson}}),_c('Download',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.getJson(item.id)}}}),_c('Delete',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteModal(item.id)}}}),_c('AccountTieVoice',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.showCopyWithChangeModal(item.id)}}}),_c('ImageAlbum',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.getDownloadZipImages(item.id)}}})],1)]}}])})],1)],1),_c('HotelChangeModal',{attrs:{"show":_vm.showEdit,"item":_vm.item},on:{"close":_vm.closeModal,"accept":_vm.editItem}}),_c('DeleteConfirmModal',{attrs:{"show":_vm.showDelete},on:{"close":_vm.hideDelete,"accept":_vm.deleteItem}}),_c('HotelChangeUser',{attrs:{"show-modal":_vm.showCopyWithChange},on:{"close":_vm.hideCopyWithChange,"accept":_vm.confirmCopyWithChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }