<template>
    <CModal
            @update:show="closeModal"
            :title="`Copy hotel and change owner`"
            color="primary"
            :show="showModal"
            centered
    >
        <CSelect
                label="User"
                :options="userList"
                :value.sync="selectedUser"
        />
    </CModal>
</template>

<script>
  import {CModal,CSelect} from "@coreui/vue";
  import { userModule } from "@/store/modules/userModule";
  export default {
    name: "HotelChangeUser",
    components:{
      CModal,
      CSelect
    },
    methods:{
      ...userModule.mapActions(['getUserList']),
      closeModal(show, event, accept) {
        if (accept) {
          this.$emit("accept",this.selectedUser);
        } else {
          this.$emit("close");
        }
      },
    },
    props:{
      showModal: {
        type: Boolean,
        default: false,
      },
    },
    data(){
      return{
        selectedUser:"",
      }
    },
    computed:{
      ...userModule.mapGetters(["getUserListData"]),
      userList(){
        return this.getUserListData.reduce((acc,item) => {
          const data = {
            value:item.id,
            label:item.name
          };
          acc.push(data);
          return acc;
        },[])
      }
    },
    async mounted() {
      if(!this.getUserListData.length){
        await this.getUserList();
      }
    }
  }
</script>

<style scoped>

</style>
